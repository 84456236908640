import {
  ThirdwebProvider,
  ConnectWallet,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  safeWallet,
  trustWallet,
  zerionWallet,
  bloctoWallet,
  frameWallet,
  rainbowWallet,
  phantomWallet,
  Web3Button,
  useWallet,
  useAddress
} from "@thirdweb-dev/react";
// import "./styles/Home.css";
import { Box, Container, Flex, Heading, SimpleGrid, Stack, Text, useToast, Button } from "@chakra-ui/react";
import Base from "antd/es/typography/Base";




export default function Home() {
  const toast = useToast()
  const walletInstance = useAddress();
  function ToggleMenu() {
    const menu = document.getElementById('Mobile-Nav');
    const openmenu = document.getElementById('openmenu');
    const closemenu = document.getElementById('closemenu');
    const overlay = document.getElementById('overlay');
    menu.classList.toggle('hidden');
    openmenu.classList.toggle('hidden');
    closemenu.classList.toggle('hidden');
    overlay.classList.toggle('hidden');
  }

  if (document.querySelector(".cursor") && document.querySelector(".cursor-follower")) {

    var cursor = document.querySelector(".cursor");
    var follower = document.querySelector(".cursor-follower");

    var posX = 0,
      posY = 0;

    var mouseX = 0,
      mouseY = 0;

    function moveCursor() {
      posX += (mouseX - posX) / 9;
      posY += (mouseY - posY) / 9;

      follower.style.left = posX - 12 + "px";
      follower.style.top = posY - 12 + "px";

      cursor.style.left = mouseX + "px";
      cursor.style.top = mouseY + "px";
    }

    function handleMouseMove(e) {
      mouseX = e.clientX;
      mouseY = e.clientY;
    }

    function handleLinkEnter() {
      cursor.classList.add("active");
      follower.classList.add("active");
    }

    function handleLinkLeave() {
      cursor.classList.remove("active");
      follower.classList.remove("active");
    }

    setInterval(moveCursor, 16);

    document.addEventListener("mousemove", handleMouseMove);

    var links = document.querySelectorAll(".link");
    links.forEach(function (link) {
      link.addEventListener("mouseenter", handleLinkEnter);
      link.addEventListener("mouseleave", handleLinkLeave);
    });
  }


  return (
    <header className="lg:pb-0 " id="Headertag">
      {/* <div className="cursor"></div>
      <div className="cursor-follower"></div> */}
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* <!-- lg+ --> */}
        <nav className="flex items-center justify-between h-16 lg:h-20">
          <div className="flex-shrink-0 HeaderLogo">
            <a href="/" title="" className="flex" style={{ flexDirection: "column" }}>
              <img className="w-auto h-8 lg:h-10" src="https://slyreum.com/assets/images/logo.png" alt="Slyreum" />
              {/* <h5 style={{color:"white",textAlign: "center"}}>Next Gen Network</h5> */}
            </a>
          </div>

          <button type="button" className="inline-flex p-2 text-white transition-all duration-200 rounded-md lg:hidden" onClick={ToggleMenu} style={{ zIndex: "1000" }}>
            {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}
            <svg id="openmenu" className="block w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8h16M4 16h16" />
            </svg>

            {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
            <svg id="closemenu" className="hidden w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <div className="DesktopMenu lg:flex lg:items-center lg:ml-auto lg:space-x-10">
            <a href="/target" title="" className="text-base font-medium text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> WhitePaper </a>
            <a href="/presale" title="" className="text-base font-medium text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> PreSale </a>
            <a href="https://x.com/Slyreum" title="" className="text-base font-medium text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> X.com </a>
          </div>

          <ConnectWallet style={{ borderColor: "white", minWidth: "130px" }}
            theme={"dark"}
            switchToActiveChain={true}
            modalSize={"wide"}
            hideTestnetFaucet={true}
            className="ConnectButton-margin"
            auth={{ loginOptional: false }}
          />
        </nav>

        {/* <!-- xs to lg --> */}
        <nav className="pt-4 pb-4 bg-white border border-gray-200 rounded-md shadow-md lg:hidden hidden" id="Mobile-Nav" style={{ backgroundColor: "#080916" }}>
          <div className="flow-root">
            <div className="flex flex-col px-6 -my-2 space-y-1">
              <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: "25px" }}>
                <a style={{ color: "white" }} href="/target" title="" className="inline-flex py-2 text-base font-medium text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> WhitePaper </a>
                <a style={{ color: "white" }} href="/presale" title="" className="inline-flex py-2 text-base font-medium text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> PreSale </a>
                <a style={{ color: "white" }} href="https://x.com/Slyreum" title="" className="inline-flex py-2 text-base font-medium text-white transition-all duration-200 hover:text-blue-600 focus:text-blue-600"> X.com </a>
              </div>
            </div>
          </div>

        </nav>
      </div>
      <div className="overlay hidden" id="overlay" style={{backdropFilter: "blur(2px)"}}>

      </div>
    </header>

  );
}
