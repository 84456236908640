import React, { useEffect, useState } from "react";
import "./styles/NHome.css";
import { useToast } from "@chakra-ui/react";

function Home() {
    const [text, setText] = useState("Either do nothing or if you do something, change everything...");
    const [displayText, setDisplayText] = useState("");
    const [index, setIndex] = useState(0);
    var timer = false

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            setDisplayText((prevText) => {
                if (index === text.length) {
                    setIndex(text.length)
                    return text;
                }
                return prevText + text[index];
            });
            setIndex((prevIndex) => prevIndex + 1);
        }, 150);

        return () => clearTimeout(typingTimeout);
    }, [index, text]);
    
    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh", flexDirection: "column" }}>
                <h1 style={{ color: "white" }} className="typing">
                    {displayText}
                </h1>
                <div style={{ display: "flex", gap: "20px" }}>
                    {/* <a href="/target" style={{ color: "white" }}>WhitePaper</a>
                    <a href="/presale" style={{ color: "white" }}>PreSale</a> */}
                    <a href="/Target" className="assetOne" style={{ color: "white", position: "relative", top: "0", right: "0" }}>
                        <img src={"https://static.vecteezy.com/system/resources/thumbnails/018/990/230/small_2x/3d-realistic-render-ui-element-approved-blue-round-circle-sparkle-star-shape-tick-check-mark-complete-ok-icon-design-vector.jpg"} alt={"Slyreum"} className="assetLogo" style={{borderRadius: "100%"}} />
                        {"White Paper"}
                        {/* <DownOutlined /> */}
                    </a>
                    <a href="/PreSale" className="assetOne" style={{ color: "white", position: "relative", top: "0", right: "0" }}>
                        <img src={"https://slyreum.com/assets/images/0xe0978fac0EeB492AA4DB95f22b893E71EC56F86C.png"} alt={"Slyreum"} className="assetLogo" />
                        {"Buy Slyreum"}
                        {/* <DownOutlined /> */}
                    </a>
                </div>
            </div>
            <div className="overlay2" id="overlay" style={{ zIndex: "-2" }}>
            </div>
            <div className="overlay" id="overlay" style={{ zIndex: "-1" }}>
            </div>
        </>
    );
}

export default Home;
