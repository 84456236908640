import "./styles/Target.css";


function Target() {

    return (
        <>
            <div class="container">
                <div class="timeline">
                    <ul>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">MARCH 2018</h3>
                                <h1>LAUNCH DAY</h1>
                                <p>ETHERGEM IS LAUNCHED AS A ETH COMPATIBLE PROJECT WITH A CUSTOM CODEBASE TO FACILITATE A BLOCK TIME OF 12 SECONDS.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">APRIL 2018</h3>
                                <h1>OPAL WALLET</h1>
                                <p>ETHERGEM GET ITS FIRST DESKTOP WALLET.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">APRIL 2018</h3>
                                <h1>SAPPHIRE (SFRX)</h1>
                                <p>THE DEVELOPMENT OF SAPPHIRE STARTS. SAPPHIRE IS A TRUEDEX WITH 5 SECOND TRANSACTIONS OF COINS FROM MULTIPLE BLOCKCHAIN NETWORKS. DEVELOPMENT IS ONGOING.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">JULY 2018</h3>
                                <h1>QUARRYNODES(QN)</h1>
                                <p>QUARRYNODES INTEGRATION ON THE ETHERGEM NETWORK.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">NOV 2018</h3>
                                <h1>TREZOR & LEDGER</h1>
                                <p>ETHERGEM GETS TREZOR & LEDGER WALLET INTEGRATION.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">OCT 2018</h3>
                                <h1>PIRLGUARD</h1>
                                <p>ETHERGEM GETS A CUSTOM INTEGRATION OF PIRLGUARD TO HELP PROTECT AGAINST 51% ATTACKS.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">SEPT 2018</h3>
                                <h1>SFRX SNAPSHOT</h1>
                                <p>A SAPPHIRE SNAPSHOT TAKEN AT BLOCK 1530000. RATIO 2:1 SFRX:EGEM.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">AUG 2018</h3>
                                <h1>QN & BOT</h1>
                                <p>ADDED DISCORD BOT INTERACTION FOR QUARRYNODES.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">FEB 2019</h3>
                                <h1>QN MULTI SIG</h1>
                                <p>QUARRYNODE - MULTI SIG WALLET SET UP.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">APRIL 2019</h3>
                                <h1>HALVING (ERA1)</h1>
                                <p>ETHERGEM HALVING AT BLOCK 2,500,001. (ERA1 STARTS.)</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">JULY 2019</h3>
                                <h1>QN UPGRADE</h1>
                                <p>QUARRYNODE MANAGEMENT SYSTEM UPGRADE.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">APRIL 2019</h3>
                                <h1>DOCKER FOR QN</h1>
                                <p>QUARRYNODE - 1 CLICK DEPLOYMENT FOR DOCKER WITH FULL CHAINDATA UPGRADE.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">FEB 2020</h3>
                                <h1>ANDROID WALLET</h1>
                                <p>ANDROID MOBILE WALLET BETA RELEASED FOR TESTING.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">APRIL 2020</h3>
                                <h1>HALVING (ERA2)</h1>
                                <p>ETHERGEM HALVING AT BLOCK 5,000,001. (ERA2 STARTS.)</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">2020</h3>
                                <h1>SFRX PROOF</h1>
                                <p>SFRX IS UNDER CONTINUOUS DEVELOPMENT. PROOF OF 5 SEC BITCOIN TRANSFER. (YOUTUBE)</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">2020</h3>
                                <h1>SFRX QR CODE</h1>
                                <p>QR CODE INTERACTION TO DISCORD AND TRADING. SFRX COMPLETED TRADES: BTC, LTC, PIRL, ETHER-1, USDC and ETTT. (YOUTUBE)</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">FEB 2021</h3>
                                <h1>EXCHANGES</h1>
                                <p>EGEM LISTED ON MERCATOX AND IS CURRENTLY AVAILABLE AT:</p>
                                <p>MERCATOX.</p>
                                <p>GRAVIEX.</p>
                                <p>STEX.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">APRIL 2021</h3>
                                <h1>HALVING (ERA3)</h1>
                                <p>ETHERGEM HALVING AT BLOCK 7,500,001. (ERA3 STARTS.)</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">AUG 2021</h3>
                                <h1>EGEM CORE</h1>
                                <p>ETHERGEM SUCCESSFULLY FORKED A BIG UPDATE TO IT’S MAIN CODEBASE.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">OCT 2021</h3>
                                <h1>RUBYSWAP</h1>
                                <p>RUBYSWAP INTRODUCED ETHERGEM TO THE WORLD OF DEFI.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">FEB 2022</h3>
                                <h1>BIDIFY</h1>
                                <p>BIDIFY LAUNCHED ON ETHERGEM. BRINGING THE FIRST OPEN NFT MARKETPLACE ON THE NETWORK.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">FEB 2022</h3>
                                <h1>BRIDGED SWAP</h1>
                                <p>WEGEM AVAILABLE ON:
                                    <p>RUBYSWAP.</p>
                                    <p>PANCAKESWAP.</p>
                                    <p>BENSWAP.</p>
                                    <p>TRADERJOE.</p>
                                </p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">MARCH 2022</h3>
                                <h1>HALVING (ERA4)</h1>
                                <p>ETHERGEM HALVING AT BLOCK 10,000,001. (ERA4 STARTS.)</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">ONGOING DEVELOPMENT BY EGEM TEAM</h3>
                                <h1>DAO</h1>
                                <p>TO BE FULL DECENTRALIZED ETHERGEM IS CURRENTLY WORKING ON INTEGRATING A GOVERNING DAO</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">ONGOING DEVELOPMENT BY SFRX TEAM</h3>
                                <h1>SFRX</h1>
                                <p>SAPPHIRE DEVELOPMENT IS ONGOING WITH ITS PROOF OF 5 SEC COIN TRANSFERS.</p>
                            </div>
                        </li>
                        <li>
                            <div class="timeline-content">
                                <h3 class="date">ONGOING DEVELOPMENT BY ZEBROCOIN TEAM</h3>
                                <h1>ZEBROCOIN</h1>
                                <p>ZEBROCOIN IS ADDING EGEM TO ITS OWN BRIDGE INTERFACE, EXPANDING EGEM’S REACH TO THESE NETWORKS: POLYGON, FANTOM, HARMONY, PHOENIX.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="lines" id="lines">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>

        </>
    );
}

export default Target;
