import React, { useState, useEffect } from "react";
import { Input, Popover, Radio, Modal, message, Skeleton } from "antd";
import {
  ArrowDownOutlined,
  DownOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import tokenList from "./tokenList.json";
import "./styles/Swap.css";
import { Web3Button, useAddress, useBalance, useChainId, useConnectionStatus, useContract, useContractWrite } from "@thirdweb-dev/react";
import { Button, useToast } from "@chakra-ui/react";
import { utils } from "ethers";
import '@dotlottie/player-component';



function Swap() {
  const contractAddress = "0x10be52B1a8f7a771F0Dc35797901E319D40aFA47";
  const TokenAddress = "0xe0978fac0EeB492AA4DB95f22b893E71EC56F86C";
  const toast = useToast()
  const connectionStatus = useConnectionStatus();
  const walletInstance = useAddress();
  const blc = useBalance();
  const chainId = useChainId();
  const slyblc = useBalance(TokenAddress);
  const minBuy = 0.1

  const [tokenOne] = useState(tokenList[0]);
  const [tokenTwo] = useState(tokenList[0]);
  const [isOpen, setIsOpen] = useState(false);

  const [amount, setAmount] = useState('');
  const [WithdrawalAmount, setWithdrawalAmount] = useState('');
  const [slyamount, setSlyAmount] = useState('');
  const handleAmountChange = (event) => {
    const val = event.target.value
    setAmount(val);
    const slyGive = val * 1000000;
    if (slyGive) {
      setSlyAmount(slyGive);
    } else {
      setSlyAmount('');
    }
  };

  const handleWithdrawalAmountChange = (event) => {
    const val = event.target.value
    const UsableVal = val * 10 ** 18;
    setWithdrawalAmount(UsableVal);
  };

  const isDisabled = !amount.trim() || parseFloat(amount) === 0 || !(connectionStatus == "connected") || parseFloat(amount) < minBuy


  function openModal(asset) {
    // setChangeToken(asset);
    setIsOpen(true);
  }


  const [modal2Open, setModal2Open] = useState(false);

  if (document.querySelector('html')) {
    document.querySelector('html').style.height = "100%";
  }


  return (
    <>
      <Modal
        title="Slyreum"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        {/* <iframe style={{ margin: "auto" }} src="https://lottie.host/embed/1d2e56d6-1df6-4fa4-835f-d1667b082c2b/32TVzGs19O.json"></iframe> */}
        <dotlottie-player src="https://lottie.host/1d2e56d6-1df6-4fa4-835f-d1667b082c2b/32TVzGs19O.json" background="transparent" speed="1" style={{ width: '70%', margin: "auto" }} loop autoplay></dotlottie-player>
        <h1 style={{ textAlign: "center", color: "white", fontWeight: "bold", fontSize: "18px", fontFamily: "system-ui" }}>Waiting For Confirmation</h1>
        <h5 style={{ textAlign: "center", color: "#7b7b7b", fontWeight: "600", fontSize: "13px", fontFamily: "system-ui" }}>Please Confirm this transaction in your wallet</h5>
      </Modal>

      <div className="TradeBoxDiv">
        {connectionStatus === "connected" && slyblc.data ? (
          <>
            {parseFloat(slyblc.data.displayValue) > 0 ? (
              <div style={{ background: "url('https://slyreum.com/assets/images/bc.png') center/cover no-repeat fixed", borderRadius: "15px" }} className="AnimatedBg BoxesMargin">
                <div className="tradeBox SwapBanner" style={{ minHeight: "auto", backgroundColor: "rgba(0, 0, 0, 0.5)", display: "flex", padding: "20px 50px", flexDirection: "unset", gap: "20px", border: "none" }}>
                  <img width={"20%"} src="https://slyreum.com/assets/images/coin.png" alt="Slyreum" />
                  <div>
                    <div style={{ display: "flex", flexDirection: "unset", gap: "5px", alignItems: "center" }}>
                      <h1 style={{ color: "white", fontSize: "22px" }}>Congratulations, you are using Slyreum</h1>
                      <img className="BannerEmoji" src="https://slyreum.com/assets/images/starstruckemoji.png" />
                    </div>
                    <p style={{ color: "#a5a4a4" }} className="sm-hidden">lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem</p>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ background: "url('https://slyreum.com/assets/images/bc.png') center/cover no-repeat fixed", borderRadius: "15px" }} className="AnimatedBg BoxesMargin">
                <div className="tradeBox SwapBanner" style={{ minHeight: "auto", backgroundColor: "rgba(0, 0, 0, 0.5)", display: "flex", padding: "20px 50px", flexDirection: "unset", gap: "20px", border: "none" }}>
                  <img width={"20%"} src="https://slyreum.com/assets/images/coin.png" alt="Slyreum" />
                  <div>
                    <div style={{ display: "flex", flexDirection: "unset", gap: "5px", alignItems: "center" }}>
                      <h1 style={{ color: "white", fontSize: "22px" }}>Oh, you don't have Slyreum</h1>
                      <img className="BannerEmoji" src="https://slyreum.com/assets/images/sademoji.png" />
                    </div>
                    <p style={{ color: "#a5a4a4" }} className="sm-hidden">lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem</p>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div style={{ background: "url('https://slyreum.com/assets/images/bc.png') center/cover no-repeat fixed", borderRadius: "15px" }} className="AnimatedBg BoxesMargin">
            <div className="tradeBox SwapBanner" style={{ minHeight: "auto", backgroundColor: "rgba(0, 0, 0, 0.5)", display: "flex", padding: "20px 50px", flexDirection: "unset", gap: "20px", border: "none" }}>
              <img width={"20%"} src="https://slyreum.com/assets/images/coin.png" alt="Slyreum" />
              <div>
                <div style={{ display: "flex", flexDirection: "unset", gap: "5px", alignItems: "center" }}>
                  <h1 style={{ color: "white", fontSize: "22px" }}>Connect wallet to use Slyreum</h1>
                  <img className="BannerEmoji" src="https://slyreum.com/assets/images/thinkingemoji.png" />
                </div>
                <p style={{ color: "#a5a4a4" }} className="sm-hidden">lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem</p>
              </div>
            </div>
          </div>
        )}

        <div className="tradeBox BoxesMargin">

          <div className="inputs">
            <Input
              id="bnbinput"
              placeholder="0"
              type="text"
              onChange={handleAmountChange}
              disabled={!(connectionStatus == "connected") || !(chainId === 97)}
            />
            <Input placeholder="0" value={slyamount.toLocaleString()} disabled={true} />
            <div className="switchButton" style={{ color: "white", cursor: "grabbing" }}
            //   onClick={switchTokens}
            >
              <ArrowDownOutlined className="switchArrow" />
            </div>
            <div className="assetOne" style={{ color: "white" }}>
              <img src={tokenList[1].img} alt={tokenOne.name} className="assetLogo" />
              {"BNB"}
              {/* <DownOutlined /> */}
            </div>
            <div className="assetOne assetOneAmount" style={{ color: "white" }}>
              {connectionStatus === "connected" && (
                <>
                  {chainId === 97 ? (
                    <>
                      {blc.data ? (
                        parseFloat(blc.data.displayValue).toFixed(3)
                      ) : null}
                    </>
                  ) : null}
                </>
              )}
            </div>

            <div className="assetTwo" style={{ color: "white" }}>
              <img src={tokenTwo.img} alt={tokenTwo.name} className="assetLogo" />
              {tokenTwo.ticker}
              {/* <DownOutlined /> */}
            </div>
            {/* <div className="assetTwo assetTwoAmount" style={{ color: "white" }}>
              {connectionStatus === "connected" && (
                <>
                  {chainId === 97 ? (
                    <>
                      {slyblc.data ? (
                        parseFloat(slyblc.data.displayValue)
                      ) : null}
                    </>
                  ) : null}
                </>
              )}
            </div> */}
          </div>
          <div className="balancetagsdiv" style={{ display: "flex", gap: "1px", width: "100%", margin: "2% 0%" }}>
          </div>

          {parseFloat(amount) < minBuy ? (
            <p className="minimumtext" style={{ color: "#ff4e4e" }}>The minimum value for this token is 0.1 BNB</p>
          ) : (
            <p></p>
          )}

          <Web3Button
            contractAddress={contractAddress}
            className="W3Button-margin"
            style={{ minWidth: "100%" }}
            action={async (contract) => {
              setModal2Open(true)
              try {
                if (!(parseFloat(amount) > parseFloat(blc.data.displayValue))) {
                  await contract.call("buyTokens", [walletInstance], { value: utils.parseEther(amount) });
                  setModal2Open(false)
                  toast({
                    title: 'SUCCESS',
                    description: "Transaction Success, You have received Slyreum Token 🥳",
                    status: 'success',
                    duration: 3000,
                    isClosable: false,
                  })
                } else {
                  setModal2Open(false)
                  toast({
                    title: 'ERROR',
                    description: "Your balance is insufficient to complete this transaction 😣",
                    status: 'error',
                    duration: 3000,
                    isClosable: false,
                  })
                }
              } catch (error) {
                // console.log(error);
                setModal2Open(false)
                toast({
                  title: 'ERROR',
                  description: "Transaction faild, Please try again 😞",
                  status: 'error',
                  duration: 3000,
                  isClosable: false,
                })
              }
            }}
            isDisabled={isDisabled}
          >Buy Token</Web3Button>
        </div>


        {walletInstance === "0xB9Ff433f0AAe6c99a8c62acbE36F9BA44fcC767B" ? (
          <div className="AdminButtons" style={{ display: "flex", gap: "5%", width: "100%", width: "700px", margin: "1% auto" }}>
            <Web3Button
              contractAddress={contractAddress}
              className="AdminButton1"
              style={{ width: "50%", margin: "auto" }}
              action={async (contract) => {
                try {
                  await contract.call("withdrawCoins", []);
                  toast({
                    title: 'SUCCESS',
                    description: "Transaction Success, BNB Transferred to your wallet 🥳",
                    status: 'success',
                    duration: 3000,
                    isClosable: false,
                  })
                } catch (error) {
                  console.log(error);
                  toast({
                    title: 'ERROR',
                    description: "Transaction faild, Please try again 😞",
                    status: 'error',
                    duration: 3000,
                    isClosable: false,
                  })
                }
              }}
            >withdraw Coins</Web3Button>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Input
                placeholder="0"
                type="text"
                onChange={handleWithdrawalAmountChange}
                disabled={!(connectionStatus == "connected") || !(chainId === 97)}
                style={{
                  height: "40px", fontSize: "25px", width: "100%", borderBottomLeftRadius: "0", borderBottomRightRadius: "0", margin: "0", border: "2px solid #21273a"
                }}
              />
              <Web3Button
                contractAddress={contractAddress}
                style={{ width: "100%", margin: "auto", borderTopLeftRadius: "0", borderTopRightRadius: "0" }}
                action={async (contract) => {
                  try {
                    if (!(parseFloat(amount) > parseFloat(blc.data.displayValue))) {
                      await contract.call("withdrawTokens", [TokenAddress, WithdrawalAmount]);
                      toast({
                        title: 'SUCCESS',
                        description: "Transaction Success, You have received Slyreum Token 🥳",
                        status: 'success',
                        duration: 3000,
                        isClosable: false,
                      })
                    } else {
                      toast({
                        title: 'ERROR',
                        description: "Your balance is insufficient to complete this transaction 😣",
                        status: 'error',
                        duration: 3000,
                        isClosable: false,
                      })
                    }
                  } catch (error) {
                    console.log(error);
                    toast({
                      title: 'ERROR',
                      description: "Transaction faild.",
                      status: 'error',
                      duration: 3000,
                      isClosable: false,
                    })
                  }
                }}
              >
                withdraw Tokens</Web3Button>
            </div>
          </div>
        ) : null}
      </div>


    </>
  );
}

export default Swap;
