import React from "react";
import { createRoot } from "react-dom/client";
import Header from "./Header";
import App from "./App";
import Home from "./Home";
import Swap from "./Swap";
import Target from "./Target";
import reportWebVitals from "./reportWebVitals";
import "./styles/globals.css";
import { ChakraProvider } from "@chakra-ui/react";

import {
  ThirdwebProvider,
  ConnectWallet,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  safeWallet,
  trustWallet,
  zerionWallet,
  bloctoWallet,
  frameWallet,
  rainbowWallet,
  phantomWallet,
  Web3Button,
  useWallet,
  useAddress,
  useConnectionStatus
} from "@thirdweb-dev/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";


const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ChakraProvider>
    <ThirdwebProvider
      autoConnect={true}
      activeChain="binance-testnet"
      clientId="834e3770247ff57d8c02e2f27ae04545"
      supportedWallets={[
        metamaskWallet({ recommended: true }),
        coinbaseWallet(),
        walletConnect(),
        safeWallet({
          personalWallets: [
            metamaskWallet({ recommended: true }),
            coinbaseWallet(),
            walletConnect(),
            trustWallet(),
            zerionWallet(),
            bloctoWallet(),
            frameWallet(),
            rainbowWallet(),
            phantomWallet(),
          ],
        }),
        trustWallet(),
        zerionWallet(),
        bloctoWallet(),
        frameWallet(),
        rainbowWallet(),
        phantomWallet(),
      ]}
    >
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/target" element={<Target />} />
          <Route path="/presale" element={<Swap />} />
        </Routes>
      </BrowserRouter>
    </ThirdwebProvider>
  </ChakraProvider>
);
